
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import EUIBasicUsage from "@/views/resources/documentation/element-ui/form/rate/BasicUsage.vue";
import EUIWithAllowHalf from "@/views/resources/documentation/element-ui/form/rate/WithAllowHalf.vue";
import EUIWithText from "@/views/resources/documentation/element-ui/form/rate/WithText.vue";
import EUIMoreIcons from "@/views/resources/documentation/element-ui/form/rate/MoreIcons.vue";
import EUIReadOnly from "@/views/resources/documentation/element-ui/form/rate/ReadOnly.vue";

export default defineComponent({
  name: "rate",
  components: {
    EUIBasicUsage,
    EUIWithAllowHalf,
    EUIWithText,
    EUIMoreIcons,
    EUIReadOnly
  },
  setup() {
    setCurrentPageTitle("Rate");
  }
});
